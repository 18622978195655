//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState, useCallback } from "react";
import * as yup from "yup";
//-----------------------------------------------------------// External Imports // ------------------------------

import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { InputLabel } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withSnackbar } from "notistack";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import NotesAttachments from "./NotesAttachments";
import Loader from "../common/Loader";
import CompensationContainer from "./Compensation";
import StrengthsAndPotentialIssues from "./StrengthsAndPotentialIssues";
import Searches from "./Searches";
import Athena from "./Athena";
import Attributes from "./Attributes";
import ActivityLog from "./ActivityLog";
import TabLayoutWithHeader from "../TabLayoutWithHeader";
import DetailsView from "./Details";
import PublishedBio from "./PublishedBio";
import { getOldValue } from "./utils";
import { getCleanedContact } from "../AddContact/utils";
import References from "./References";
import ProfileAndActionsView from "./ProfileAndActionsView";
import { getUserSelector } from "../../selectors";
import "./index.scss";
import {
  cleanupFormValues,
  compareByPosition,
  customFormValidator,
  customFormValidatorInline,
  isEmpty,
  requireValidMessage,
  sortByChronologicalOrder,
} from "../../utils/common";
import { getAccessToken } from "../../services/cognitoService";
import ConfirmationPopup from "../ConfirmationPopup";
import {
  API,
  EMAIL_REGEX,
  GET,
  POST,
  PUT,
  WARNING,
  ERROR,
  SUCCESS,
  POSITIVE_INTEGER_WITH_ZERO_REGEX,
  INLINE_EDIT_RECORD_VALIDATION_MESSAGE,
} from "../../services/constantService";
import { fetchUserList } from "../../../src/actions";
import {
  acquireLockApi,
  contactDataApi,
  releaseLockApi,
  renewLockApi,
} from "../../services/ApiService";
import {
  validateMessage,
  unableMessage,
  notFoundMessage,
  successMessage,
  VALIDATION_MESSAGE,
  requireMessage,
} from "../../services/MessageService";
import { contactActions } from "../../store/contactSlice";
import EmploymentHistoryConfirmationDialog from "../../Containers/Commons/EmploymentHistoryConfirmationDialog";
import {
  SPECIAL_CHARACTERS_REPLACE_REGEX,
  REPLACE_SPACE_REGEX,
} from "../../services/constantService";
import { handelScroll } from "../../utils/common";
import { getScreeningNotesDefaultValue } from "../ActivityLog/utils";
import { showSnackbar } from "../../Containers/Commons/Utils";
import { validateStartYearEndYear } from "../../utils/date";
// import { contactSchema } from "../ActivityLog/utils";
const NAV_ITEMS = [
  "Details",
  "Compensation",
  "Strengths & Potential Issues",
  "Activity Log",
  "Searches",
  "Published Bio",
  "Notes and Attachments",
  "References",
  "Athena",
  "Leadership Competencies",
];
const getDefaultValues = (data) => {
  const defaultValues = { ...data };
  defaultValues.current_company = data.company;
  defaultValues.country = typeof data.country === "object" ? data.country : data.country;
  defaultValues.mailing_list = defaultValues.mailing_list_user;
  defaultValues.international_experience = data.international_experience || [];
  const partnerRelation = data.partner_relation;
  defaultValues.partner_relation =
    Array.isArray(partnerRelation) && partnerRelation.length > 0 ? partnerRelation : [{}];
  defaultValues.industries = Array.isArray(defaultValues.industries)
    ? defaultValues.industries
    : [];
  defaultValues.job_history = Array.isArray(data.job_history)
    ? sortByChronologicalOrder(data.job_history)
    : [];
  defaultValues.board_details = Array.isArray(data.board_details)
    ? data.board_details.sort(compareByPosition)
    : [];
  defaultValues.education_details = data.education_details?.sort(compareByPosition);
  defaultValues.screening_notes = getScreeningNotesDefaultValue(defaultValues?.screening_notes);
  return defaultValues;
};

const ViewContact = (props) => {
  const { enqueueSnackbar, closeSnackbar } = props;
  const { id, currentTab } = useParams();

  const newNavItems = NAV_ITEMS.map((item) => {
    item = item.replace(SPECIAL_CHARACTERS_REPLACE_REGEX, "and");
    return item.toLowerCase().replace(REPLACE_SPACE_REGEX, "-");
  });
  const history = useHistory();
  let currentTabIndex = newNavItems.findIndex((item) => item === currentTab);
  currentTabIndex =
    currentTabIndex === -1 ? history.push(`/contacts/${id}/${newNavItems[0]}`) : currentTabIndex;
  const location = useLocation();
  const [contact, setContact] = useState(null);
  const [video, setVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [defaultTab] = useState(0);
  const {
    register,
    watch,
    setValue,
    unregister,
    reset,
    control,
    getValues,
    triggerValidation,
    errors,
    handleSubmit,
    formState,
  } = useForm();
  const { dirty } = formState;
  const [profileUrl, setProfileUrl] = useState(null);
  const [editingField, setEditingField] = useState("");
  const [hoveredField, setHoveredField] = useState("");
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const [isLastSaveSuccess, setLastSaveSuccess] = useState(true);
  const [saveFromEdit, setSaveFromEdit] = useState(false);
  const userData = useSelector(getUserSelector);
  const users = useSelector((state) => state.rootReducer.users);
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [showJobTitleChangedPopup, setShowJobTitleChangedPopup] = useState(false);
  const [isUpdateEmploymentHistory, setEmploymentHistory] = useState(false);
  const [skipCheckJobTitle, setSkipJobTitle] = useState(false);
  const [employmentHistoryMessageEndYearDate, setEmploymentHistoryEndYearDate] = useState(null);
  const [tabChanged, setTabChanged] = useState(false);
  useEffect(() => {
    register("contact_profile_pic");
  }, [register]);
  useEffect(() => {
    if (location?.state?.data) {
      location.data = location.state.data;
      location.data.isEdit = location.state.data.isEdit;
    }
    if (location.data && location.data.isEdit) {
      setIsEditing(true);
    }
  }, [location.data, location.state]);
  useEffect(() => {
    if (!users) {
      dispatch(fetchUserList("users"));
    } else {
      setOptions(users);
    }
  }, [users, dispatch]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  useEffect(() => {
    return () => {
      dispatch(contactActions.resetContactState());
    };
  }, []);

  const renderEditIcon = (field) => {
    return (
      !(editingField === field || hoveredField !== field) && (
        <EditIcon
          className="ml-2"
          onClick={async () => {
            let { status, data } = await acquireLockApi(id);
            if (data && status && status === 200 && data.message && data.user === false) {
              //if (data.isAdmin) {
              const object = {
                enqueueSnackbar: enqueueSnackbar,
                closeSnackbar: closeSnackbar,
                message: data.message,
                id: id,
              };
              showSnackbar(object);

              /* }
          else {
            enqueueSnackbar(data.message, { variant: 'warning' });
          } */
            } else {
              setEditingField(field);
              setHoveredField("");
              window.addEventListener("beforeunload", releaseLock);
              editLockRenewTimer = setInterval(renewLock, halfAnHour);
            }
          }}
          fontSize="inherit"
          color="secondary"
        />
      )
    );
  };
  const renderActions = (field, required = false, data) => {
    let skipReload = false;
    if (data) {
      skipReload = true;
    }
    if (editingField === field) {
      return (
        <>
          <span
            className="action-icon"
            onClick={() => updateField(field, required, undefined, false, data, skipReload)}
          >
            <DoneIcon cursor="pointer" fontSize="inherit" color="primary" />
          </span>
          {!data && (
            <span
              className="action-icon cursor-pointer"
              onClick={async () => {
                await releaseLockApi(id);
                editLockRenewTimer = clearInterval(editLockRenewTimer);
                setEditingField("");
              }}
            >
              <CloseIcon cursor="pointer" fontSize="inherit" />
            </span>
          )}
        </>
      );
    }
    return null;
  };

  const isValidEmail = (data) => {
    return EMAIL_REGEX.test(data);
  };

  const updateField = async (
    field,
    required,
    listValues,
    skipMessage,
    autoSaveData,
    skipReload = true
  ) => {
    const formData = listValues ? { [field]: listValues } : getValues({ nest: true });

    if (field.includes("gdprRelease")) {
      const key = field.split(".")[0];
      const subKey = field.split(".")[1];
      const data = { [key]: { [subKey]: formData[key][subKey] } };
      data.old = getOldValue(contact, field);
      setEditingField("");
      const loadingFlag = !skipMessage;
      return await individualFieldSave(data, skipMessage, undefined, loadingFlag, skipReload);
    }

    if (formData[field] !== contact[field]) {
      field = field === "bd_target" ? "bd_target_id" : field;
      const data = { [field]: getCleanedContact(formData, true)[field] };
      data.old = getOldValue(contact, field);
      if (field === "state") {
        data.country = formData.country;
      }
      // filter extra data from the payload
      if (data.kg) {
        const arr = [];
        data.kg.forEach((element) => {
          arr.push(element.id);
        });
        data.kg = arr;
      }
      if (data.old.kg) {
        const arr = [];
        data.old.kg.forEach((element) => {
          arr.push(element.id);
        });
        data.old.kg = arr;
      }
      if (field === "work_email") {
        const result = isValidEmail(data.work_email);
        if (data.work_email.length > 0 && !result) {
          const message = validateMessage("valid work email", "enter");
          return enqueueSnackbar(`${message} ${data.work_email}`, { variant: ERROR });
        }
      }

      if (field === "private_email") {
        const result = isValidEmail(data.private_email);
        if (data.private_email.length > 0 && !result) {
          const message = validateMessage("valid private email", "enter");
          return enqueueSnackbar(`${message} ${data.private_email}`, { variant: ERROR });
        }
      }

      if (field === "age") {
        const isValidAge = ageValidation(Number(data?.age));
        if (!isValidAge) return;
      }

      // rename kg to kg_25 as per requirement for db schema
      delete Object.assign(data, { kg_25: data["kg"] })["kg"];
      delete Object.assign(data.old, { kg_25: data.old["kg"] })["kg"];
      const isEmpty = !(Array.isArray(data[field]) ? data[field].length : data[field]);
      if (required && isEmpty) {
        let requiredField = [
          { fieldName: "first_name", label: "First Name", type: String },
          { fieldName: "last_name", label: "Last Name", type: String },
          { fieldName: "current_company", label: "Company Name", type: Object },
          { fieldName: "current_job_title", label: "Job Title", type: String },
          { fieldName: "current_job_start_year", label: "Job Start Year", type: String },
          { fieldName: "country", label: "Location", type: String },
          { fieldName: "industries", label: "Industry", type: [] },
          { fieldName: "job_functions", label: "Job Functions", type: [] },
        ];
        const dirtyField = customFormValidatorInline(field, requiredField);
        if (dirtyField) {
          const message = requireMessage(dirtyField, "is");
          enqueueSnackbar(message, { variant: ERROR });
          return;
        }
      }
      setEditingField("");
      const loadingFlag = !skipMessage;
      if (autoSaveData) {
        data[field] = autoSaveData[field];
      }
      return await individualFieldSave(data, skipMessage, undefined, loadingFlag, skipReload);
    }
    setEditingField("");
    return true;
  };
  const ageValidation = (age) => {
    const isValidNumber = POSITIVE_INTEGER_WITH_ZERO_REGEX.test(age);
    if (age && !isValidNumber) {
      const message = validateMessage("valid age", "enter");
      enqueueSnackbar(`${message}`, { variant: ERROR });
      return false;
    }
    return true;
  };
  const handleMouseEnter = (field) => {
    setHoveredField(field);
  };
  const handleMouseLeave = () => {
    setHoveredField("");
  };
  const renderLabel = (label, field, required = false) => {
    return (
      <InputLabel
        required={required}
        className="contact-view-label cursor-pointer"
        onMouseEnter={() => handleMouseEnter(field)}
        onMouseLeave={handleMouseLeave}
      >
        {label}
        {renderEditIcon(field)}
      </InputLabel>
    );
  };
  const handleRichTextSave = (data) => {
    const field = Object.keys(data)[0];
    data.old = getOldValue(contact, field);
    return individualFieldSave(data);
  };
  // useEffect(() => {
  //   // if (location.search) {
  //   //   const defaultTab = location.search.split('defaultTab=')[1].split("&")[0];
  //   //   setDefaultTab(parseInt(defaultTab));
  //   // }
  // }, [location.search])
  const getContact = useCallback(
    async (skipLoading) => {
      !skipLoading && setIsLoading(true);
      const { status, data } = await contactDataApi(GET, id);
      !skipLoading && setIsLoading(false);
      if (status === 200 && data !== null) {
        getVideo();
        setContact(data);
        return data;
      } else if (status === 200) {
        const message = notFoundMessage("contact details");
        enqueueSnackbar(message, { variant: ERROR });
      } else {
        const message = unableMessage("contact details", "fetch");
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      return {};
    },
    [id, setIsLoading, enqueueSnackbar]
  );
  useEffect(() => {
    getContact();
  }, [getContact]);
  const saveContact = async (updatedContact) => {
    updatedContact.updated_at = contact.updated_at;
    return saveAPI(updatedContact);
  };
  const individualFieldSave = async (
    updatedContact,
    skipMessage,
    setAutoSaveLoading,
    loadingFlag = false,
    skipReload = true
  ) => {
    try {
      if (setAutoSaveLoading) {
        setAutoSaveLoading(true);
      }
      const result = await saveAPI(updatedContact, skipMessage, skipReload, loadingFlag);
      if (setAutoSaveLoading) {
        setTimeout(() => {
          setAutoSaveLoading(false);
        }, 1000);
      }
      return result;
    } catch (e) {
      console.log("Error found in individualFieldSave::", e);
    }
  };
  const updateProfileUrl = useCallback(async () => {
    if (contact?.image_id) {
      const token = await getAccessToken();
      setProfileUrl(`${API.contact}/${contact.id}/image?token=${token}&t=${Date.now()}`);
    } else {
      setProfileUrl(null);
    }
  }, [contact]);
  useEffect(() => {
    updateProfileUrl();
  }, [updateProfileUrl]);
  const setNewProfilePic = (profilePic) => {
    setValue("contact_profile_pic", profilePic);
  };
  const uploadProfilePic = async (newProfilePic) => {
    if (newProfilePic) {
      var bodyFormData = new FormData();
      bodyFormData.append("profile", newProfilePic);
      setIsLoading(true);
      const sub_route = "image";
      const { status, data } = await contactDataApi(POST, contact.id, bodyFormData, sub_route);
      setIsLoading(false);
      setNewProfilePic(null);
      if (status === 200) {
        const message = successMessage("Profile pic", "uploaded");
        enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      } else {
        const message = unableMessage("profile pic", "upload");
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      updateProfileUrl().then(null);
    }
  };

  const getVideo = async () => {
    const sub_route = "video";
    const { status, data } = await contactDataApi(GET, id, "", sub_route);
    if (status === 200) {
      if (status === 200 && data !== null) {
        setVideo(data);
        return data;
      }
      return {};
    }
  };

  const saveAPI = async (updatedContact, skipMessage, skipReload, loadingFlag = true) => {
    try {
      let returnResult;
      loadingFlag && setIsLoading(true);
      const { status, data } = await contactDataApi(PUT, id, updatedContact);
      if (status === 200) {
        await uploadProfilePic(updatedContact.contact_profile_pic);
        setSaveFromEdit(isEditing);
        if (!skipReload) {
          await getContact(skipMessage);
        }
        const message = successMessage("Contact", VALIDATION_MESSAGE.updated_message);
        !skipMessage && enqueueSnackbar(message, { variant: SUCCESS });
        await releaseLockApi(id);
        editLockRenewTimer = clearInterval(editLockRenewTimer);
        setIsLoading(false);
        setLastSaveSuccess(true);
        returnResult = true;
      } else if (status === 409) {
        const message = unableMessage("contact", "update");
        enqueueSnackbar(data?.message || message, {
          persist: true,
          variant: ERROR,
          // eslint-disable-next-line react/display-name
          action: (key) => (
            <Button
              size="small"
              variant="text"
              color="inherit"
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              Dismiss
            </Button>
          ),
        });
        setIsLoading(false);
        setLastSaveSuccess(false);
        returnResult = false;
      } else {
        const message = unableMessage("contact details", "update");
        enqueueSnackbar(data?.message || message, { variant: ERROR });
        setIsLoading(false);
        setLastSaveSuccess(false);
        returnResult = false;
      }
      if (skipReload) {
        delete updatedContact.old;
        let newContactData = {
          ...contact,
          ...updatedContact,
          updated_at: new Date().toLocaleString("en-US"),
        };
        setSaveFromEdit(false);
        setContact(newContactData);
      }
      return returnResult;
    } catch (e) {
      console.log("Error found in saveAPI::", e);
    }
  };
  const reloadData = async () => {
    return getContact();
  };
  useEffect(() => {
    if (contact) {
      let values = { ...contact };
      if (isEditing && saveFromEdit) {
        const formValues = getValues({ nest: true });
        values = { ...values, ...cleanupFormValues(formValues) };
      }
      setDefaultValues(getDefaultValues(values));
    }
  }, [contact, isEditing, saveFromEdit, getValues]);

  useEffect(() => {
    return () => {
      releaseLock();
    };
  }, []);

  const saveDetails = async (data, skipEditCancel) => {
    try {
      if (data?.company?.id !== data?.current_company?.id) {
        data.companyChange = true;
      } else {
        data.companyChange = false;
      }
      // filter extra data from the payload
      if (data.kg) {
        const arr = [];
        data.kg.forEach((element) => {
          arr.push(element.id);
        });
        data.kg = arr;
      }
      if (data?.age) {
        const isValidAge = ageValidation(Number(data.age));
        if (!isValidAge) return;
      }
      // rename kg to kg_25 as per requirement for db schema
      delete Object.assign(data, { kg_25: data["kg"] })["kg"];
      data.company = data.current_company;
      // condition for without company not can store data in database
      data.job_history = data.job_history.filter((item) => item?.company);

      if (
        (contact.current_job_title !== data.current_job_title ||
          contact?.company?.id !== data?.company?.id) &&
        !skipCheckJobTitle
      ) {
        setShowJobTitleChangedPopup(true);
        return false;
      }
      if (skipCheckJobTitle) {
        setSkipJobTitle(false);
        setTabChanged(false);
        if (isUpdateEmploymentHistory) {
          let newJobHistory = [
            {
              company: contact.company,
              title: contact.current_job_title,
              start_year: contact.current_job_start_year,
              end_year: employmentHistoryMessageEndYearDate || new Date().getFullYear(),
            },
          ];
          data.job_history = [...newJobHistory, ...data.job_history];
          setEmploymentHistory(false);
        }
      }

      const saved = await saveContact(getCleanedContact(data, true));
      if (!skipEditCancel) {
        releaseLock();
        setIsEditing(false);
      }
      setSaveFromEdit(false);
      return saved;
    } catch (e) {
      console.log("Error found in saveDetails::", e);
    }
  };
  const editContactSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    current_company: yup.object().required(),
    current_job_title: yup.string().required(),
    current_job_start_year: yup.string().required(),
    country: yup.string().required(),
    industries: yup.array().required(),
    job_functions: yup.array().required(),
  });
  const handleSave = async (skipEditCancel = false) => {
    try {
      await triggerValidation();
      if (
        Object.prototype.hasOwnProperty.call(errors, "private_email") ||
        Object.prototype.hasOwnProperty.call(errors, "work_email")
      ) {
        if (
          Object.prototype.hasOwnProperty.call(errors, "private_email") &&
          Object.prototype.hasOwnProperty.call(errors, "work_email")
        ) {
          const message = validateMessage("valid work email and private email", "enter");
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
        if (Object.prototype.hasOwnProperty.call(errors, "work_email")) {
          const message = validateMessage("valid work email ", "enter");
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
        if (Object.prototype.hasOwnProperty.call(errors, "private_email")) {
          const message = validateMessage("valid private email", "enter");
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
      }
      // let validateJobHistory = true;
      const formData = getValues({ nest: true });
      if (currentTabIndex === 0) {
        // validateJobHistory = await contactSchema.isValid(getValues({ nest: true }))
        const isValid = await editContactSchema.isValid(formData);
        if (!isValid) {
          let requiredField = [
            { fieldName: "first_name", label: "First Name", type: String },
            { fieldName: "last_name", label: "Last Name", type: String },
            { fieldName: "current_company", label: "Company Name", type: Object },
            { fieldName: "current_job_title", label: "Job Title", type: String },
            { fieldName: "current_job_start_year", label: "Job Start Year", type: String },
            { fieldName: "country", label: "Location", type: String },
            { fieldName: "industries", label: "Industry", type: [] },
            { fieldName: "job_functions", label: "Job Functions", type: [] },
          ];
          let dirtyField = customFormValidator(formData, requiredField);
          if (dirtyField) {
            const message = requireValidMessage(dirtyField);
            enqueueSnackbar(message, { variant: ERROR });
            return false;
          }
        }
      }
      const empHistory = formData?.job_history;
      const boardDetails = formData?.board_details;
      let invalidJobHistory = {};
      let invalidBoardDetails = {};
      let jobHistoryYear = {};
      let boardYear = {};
      jobHistoryYear = validateStartYearEndYear(empHistory);
      boardYear = validateStartYearEndYear(boardDetails);

      if (empHistory && empHistory.length > 0)
        invalidJobHistory =
          empHistory.find(
            (ele) => !ele?.company && (ele?.title || ele?.start_year || ele?.end_year)
          ) || {};
      if (boardDetails && boardDetails.length > 0)
        invalidBoardDetails =
          boardDetails.find(
            (ele) =>
              !ele.company && (ele?.title || ele?.start_date || ele?.end_date || ele?.committee)
          ) || {};

      if (
        Object.keys(errors).length !== 0 ||
        Object.keys(invalidJobHistory).length !== 0 ||
        Object.keys(invalidBoardDetails).length !== 0
      ) {
        if (Object.keys(invalidJobHistory).length !== 0) {
          const message = validateMessage("company name in employee history tab", "fill");
          props.enqueueSnackbar(message, { variant: WARNING });
          return false;
        } else if (Object.keys(invalidBoardDetails).length !== 0) {
          const message = validateMessage("company name in board details tab", "fill");
          props.enqueueSnackbar(message, { variant: WARNING });
          return false;
        }
        // else {
        //   return contactSchema.isValid(contact).then(async (valid) => {
        //     if (!valid) {
        //       const message = validateMessage("required fields in Details tab", "fill all")
        //       enqueueSnackbar(message, { variant: WARNING })
        //       return false
        //     }
        //   })
        // }
      } else {
        if (
          (jobHistoryYear && Object.keys(jobHistoryYear).length !== 0) ||
          (boardYear && Object.keys(boardYear).length !== 0)
        ) {
          enqueueSnackbar(VALIDATION_MESSAGE.start_end_year, { variant: ERROR });
          return false;
        } else {
          return saveDetails({ ...defaultValues, ...getValues({ nest: true }) }, skipEditCancel);
        }
      }
    } catch (e) {
      console.log("Error found in handleSave::", e);
    }
  };
  let retryCount = 0;
  const halfAnHour = 30 * 60 * 1000;
  let editLockRenewTimer;
  const releaseLock = async () => {
    if (isEditing || editLockRenewTimer) {
      await releaseLockApi(id);
      editLockRenewTimer = clearInterval(editLockRenewTimer);
    }
    window.removeEventListener("beforeunload", releaseLock, true);
  };

  useEffect(() => {
    setTimeout(() => {
      handelScroll();
    }, 3000);
  }, []);

  useEffect(() => {
    if (skipCheckJobTitle) {
      handleSave(tabChanged);
    }
  }, [skipCheckJobTitle]);

  const handleEdit = async () => {
    try {
      let { status, data } = await acquireLockApi(id);
      if (status && status === 200 && data?.message === undefined) {
        const contactData = await reloadData();
        reset({ ...getDefaultValues(contactData) });
        setIsEditing(true);
        window.addEventListener("beforeunload", releaseLock);
        editLockRenewTimer = setInterval(renewLock, halfAnHour);
      } else {
        //if (data.isAdmin) {
        let message;
        let lable;
        if (editingField !== "") {
          message = INLINE_EDIT_RECORD_VALIDATION_MESSAGE;
          lable = "Force Edit";
        } else {
          message = data?.message;
          lable = "Force Unlock";
        }
        enqueueSnackbar(message, {
          variant: WARNING,
          // eslint-disable-next-line react/display-name
          action: (key) => (
            <>
              <Button
                size="small"
                variant="text"
                color="inherit"
                onClick={async () => {
                  closeSnackbar(key);
                  await releaseLockApi(id);
                  setIsEditing(true);
                  setEditingField("");
                  const contactData = await reloadData();
                  reset({ ...getDefaultValues(contactData) });
                }}
              >
                {lable}
              </Button>

              <Button
                size="small"
                variant="text"
                color="inherit"
                onClick={async () => {
                  closeSnackbar(key);
                }}
              >
                CLOSE
              </Button>
            </>
          ),
        });
        /* }
        else {
          enqueueSnackbar(data.message, { variant: WARNING });
        } */
      }
    } catch (e) {
      console.log("Error found in handleEdit::", e);
    }
  };
  const renewLock = () => {
    if (document.hidden) {
      if (retryCount > 3) {
        clearInterval(editLockRenewTimer);
      }
      retryCount++;
    } else {
      retryCount = 0;
      renewLockApi(id);
    }
  };
  const handleTabChange = async (tabValue) => {
    try {
      const values = { ...defaultValues, ...getValues({ nest: true }) };
      if (tabValue === 0 && isEditing) {
        const isValid = await editContactSchema.isValid(values);
        if (!isValid) {
          let requiredField = [
            { fieldName: "first_name", label: "First Name", type: String },
            { fieldName: "last_name", label: "Last Name", type: String },
            { fieldName: "current_company", label: "Company Name", type: Object },
            { fieldName: "current_job_title", label: "Job Title", type: String },
            { fieldName: "current_job_start_year", label: "Job Start Year", type: String },
            { fieldName: "country", label: "Location", type: String },
            { fieldName: "industries", label: "Industry", type: [] },
            { fieldName: "job_functions", label: "Job Functions", type: [] },
          ];
          let dirtyField = customFormValidator(values, requiredField);
          if (dirtyField) {
            const message = requireValidMessage(dirtyField);
            enqueueSnackbar(message, { variant: ERROR });
            return false;
          }
        }
      }
      if (isEditing && dirty && tabValue < 3) {
        setTabChanged(true);
        if (tabValue === 0) {
          return handleSave(true);
        }
        await saveDetails(values, true);
      }
      reset({ ...values });
      setEditingField("");
    } catch (e) {
      console.log("Error found in handleTabChange::", e);
    }
  };

  const handelNewTabChange = (newtabValue) => {
    if (newtabValue !== undefined || newtabValue !== null) {
      history.push(`/contacts/${id}/${newNavItems[newtabValue]}`, {
        data: { isEdit: isEditing },
      });
    }
  };
  const handleCancel = async () => {
    const contactData = await reloadData();
    history.replace({ data: { isEdit: false } });
    reset({ ...getDefaultValues(contactData) });
    releaseLock();
    updateProfileUrl();
    setSaveFromEdit(false);
  };
  const goBack = () => {
    setShowConfirmBack(false);
    releaseLock();
    history.push("/contacts");
  };
  const handleConfirm = async () => {
    try {
      setShowConfirmBack(false);
      const saved = await handleSave();
      if (saved) {
        goBack();
      }
    } catch (e) {
      console.log("Error found in handleConfirm::", e);
    }
  };
  const handleBack = () => {
    if (isEditing && dirty) {
      setShowConfirmBack(true);
    } else {
      goBack();
    }
  };
  const handleClose = () => {
    setShowConfirmBack(false);
  };

  const handleConfirmJobTitlePopup = async () => {
    setEmploymentHistory(true);
    setShowJobTitleChangedPopup(false);
    setSkipJobTitle(true);
  };

  const handleCancleJobTitlePopup = async () => {
    setEmploymentHistory(false);
    setShowJobTitleChangedPopup(false);
    setSkipJobTitle(true);
  };

  const withHeader = (children, canEdit = false) => {
    return (
      <React.Fragment>
        <ProfileAndActionsView
          option={options}
          setProfileUrl={setProfileUrl}
          setNewProfilePic={setNewProfilePic}
          profileUrl={profileUrl}
          setContact={setContact}
          isEditing={isEditing}
          handleSave={handleSave}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
          setIsEditing={setIsEditing}
          contact={contact}
          video={video}
          canEdit={canEdit}
          setValue={setValue}
        />
        {children}
      </React.Fragment>
    );
  };
  const headerLayout = () => {
    return (
      <>
        <div className="cursor-pointer" onClick={handleBack}>
          <span className="header-back-icon">
            <ArrowBackIcon color="primary" />
          </span>
        </div>
        <span className="header-text">{isEditing ? "Edit Contact" : "View Contact"}</span>
      </>
    );
  };
  return (
    <React.Fragment>
      <Loader show={isLoading} />
      {showConfirmBack && (
        <ConfirmationPopup
          header={VALIDATION_MESSAGE.unsave_changes_message}
          onConfirm={handleConfirm}
          onCancel={goBack}
          onClose={handleClose}
          cancelText="Discard"
          confirmText="Save"
        />
      )}
      {showJobTitleChangedPopup && (
        <EmploymentHistoryConfirmationDialog
          header="Update"
          employmentHistoryMessageEndYearDate={employmentHistoryMessageEndYearDate}
          setEmploymentHistoryEndYearDate={setEmploymentHistoryEndYearDate}
          open={showJobTitleChangedPopup}
          contact={contact}
          handleSubmit={handleConfirmJobTitlePopup}
          handleClose={handleCancleJobTitlePopup}
          cancelText="No"
          confirmText="Yes"
          extraClassName="jobhistory-popup"
        />
      )}
      {contact && (
        <Helmet>
          <title>
            {`${contact?.first_name || ""} ${contact?.last_name || ""}` || "--"} -{" "}
            {contact?.company?.name || ""}
          </title>
        </Helmet>
      )}
      {!isEmpty(defaultValues) ? (
        <TabLayoutWithHeader
          headerLayout={headerLayout()}
          navItems={[...NAV_ITEMS]}
          selectedTabIndex={defaultTab}
          tabChange={handleTabChange}
          newTabChange={handelNewTabChange}
          contactId={id}
          currentTab={currentTabIndex}
          grid={true}
        >
          {withHeader(
            <DetailsView
              option={options}
              defaultValues={defaultValues}
              isEditing={isEditing}
              contact={contact}
              userData={userData}
              individualFieldSave={individualFieldSave}
              renderLabel={renderLabel}
              renderActions={renderActions}
              handleRichTextSave={handleRichTextSave}
              editingField={editingField}
              useForm={{ register, watch, setValue, unregister, control }}
              saveStatus={isLastSaveSuccess}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              setIsLoading={setIsLoading}
              setContact={setContact}
              handelScroll={handelScroll}
            />,
            true
          )}
          {withHeader(
            <CompensationContainer
              isEditing={isEditing}
              renderLabel={renderLabel}
              renderActions={renderActions}
              editingField={editingField}
              useForm={{ register, watch, setValue, unregister, control, handleSubmit }}
              contact={contact}
            />,
            true
          )}
          {withHeader(
            <StrengthsAndPotentialIssues
              isEditing={isEditing}
              contact={contact}
              useForm={{
                register,
                watch,
                setValue,
                unregister,
                reset,
                control,
                handleSubmit,
              }}
              updateField={updateField}
              setIsLoading={setIsLoading}
              individualFieldSave={individualFieldSave}
              handelScroll={handelScroll}
            />,
            true
          )}
          {withHeader(<ActivityLog contact={contact} />)}
          {withHeader(<Searches key={contact?.id} contact={contact} activeSearches />)}
          {withHeader(
            <PublishedBio
              key={contact?.id}
              contact={contact}
              handleRichTextSave={handleRichTextSave}
              saveStatus={isLastSaveSuccess}
              individualFieldSave={individualFieldSave}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              renderLabel={renderLabel}
              renderActions={renderActions}
              editingField={editingField}
            />
          )}
          {withHeader(
            <NotesAttachments contact={contact} video={video} handelScroll={handelScroll} />
          )}
          {withHeader(<References key={id} contact={contact} contactId={id} />)}
          {withHeader(<Athena key={contact?.id} contact={contact} />)}
          {withHeader(<Attributes key={contact?.id} contact={contact} />)}
        </TabLayoutWithHeader>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};
ViewContact.propTypes = {
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
};
export default withSnackbar(ViewContact);
